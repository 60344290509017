import type { PropsWithChildren, ReactNode } from 'react'
import { useState } from 'react'
import type { AbstractIntlMessages, RichTagsFunction } from 'next-intl'
import { NextIntlClientProvider } from 'next-intl'
import styled from 'styled-components'
import { BRAND, Brand, brandConfig } from '@/config/brand'

type TranslationProviderProps = {
  translationsFromServer?: AbstractIntlMessages
}

export const TextHighlighted = styled.span(({ theme }) => ({
  color: theme.color.text.primary,
}))

export function withRichElements(values: Record<string, string | number | RichTagsFunction | Date>) {
  return {
    ...values,
    bold: (text: ReactNode) => <TextHighlighted>{text}</TextHighlighted>,
  }
}

const TranslationProvider = ({ children, translationsFromServer }: PropsWithChildren<TranslationProviderProps>) => {
  // Used as a cache since messages are only available on first render
  const [messages] = useState(translationsFromServer)

  return (
    <NextIntlClientProvider
      locale={brandConfig.language}
      messages={messages}
      timeZone={BRAND === Brand.MTV ? 'Europe/Helsinki' : 'Europe/Stockholm'}
    >
      {children}
    </NextIntlClientProvider>
  )
}

export { TranslationProvider }
